import React, { useEffect, useRef, useState } from 'react';
// import { StringParam, useQueryParam } from 'use-query-params'
import { getImageModal } from '../../apis/commom';
import AppContainerView from '../../components/AppContainerView';
import CancelBtn from '../../components/CantelBtn';
import MobileTemplate from '../../components/MobileTemplate';
import { images } from '../../data';

const ImageModalPage = ({ location }: any) => {
  //   const dimessionWidth = Dimensions.get("screen").width
  //   const navigation = useNavigation()
  //   const route = useRoute()
  //   const {
  //     displayedAt = "auth",
  //     title = "",
  //     logPath,
  //     headerTextColor = "#000000",
  //     headerBackgroundColor = "#ffffff",
  //   } = useQueryParam()
  const paramsObj = new URLSearchParams(location.search);

  // const [displayedAt = 'auth'] = useQueryParam('displayedAt', StringParam)
  const [displayedAt = 'auth'] = useState(paramsObj.get('displayedAt'));
  // const [title] = useQueryParam('title', StringParam)
  const [title] = useState(paramsObj.get('title'));
  // const [headerTextColor] = useQueryParam('headerTextColor', StringParam)
  const [headerTextColor] = useState(paramsObj.get('headerTextColor'));
  // const [headerBackgroundColor] = useQueryParam('headerBackgroundColor', StringParam)
  const [headerBackgroundColor] = useState(paramsObj.get('headerBackgroundColor'));
  const [imageData, setImageData] = useState([]);
  const momguideRankingInfoEndDate = useRef(new Date());
  const momguideRankingInfoStartDate = useRef(new Date());

  useEffect(() => {
    momguideRankingInfoEndDate.current = new Date();
    init();
  }, [displayedAt]);

  const init = async () => {
    const res = await getImageModal(displayedAt);
    setImageData(res.rows);
  };

  const onClose = () => {
    momguideRankingInfoEndDate.current = new Date();
    const duration = Math.round((momguideRankingInfoEndDate.current.getTime() - momguideRankingInfoStartDate.current.getTime()) / 1000);
    const logDate = {
      durationSeconds: duration,
    };

    // amplitudeLog(logPath, logDate);
    // navigation.goBack()
  };

  return (
    <AppContainerView>
      <MobileTemplate>
        <div style={{ flex: 1 }}>
          <div
            style={
              { backgroundColor: headerBackgroundColor } //headerContainer
            }
          >
            {/* <div style={styles.headerLeftView} /> */}
            <span style={{ fontSize: 14, color: headerTextColor }}>{title}</span>
            <CancelBtn
              style={{ position: 'absolute', right: 20, cursor: 'pointer' }}
              width={30}
              height={30}
              onClick={onClose}
              color={headerTextColor}
            />
          </div>
          {imageData.map((item) => (
            <img src={item?.url} />
          ))}
        </div>
      </MobileTemplate>
    </AppContainerView>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     height: "100%",
//     backgroundColor: "#ffffff",
//   },
//   headerContainer: {
//     flexDirection: "row",
//     height: 59,
//     alignItems: "center",
//     justifyContent: "space-between",
//     paddingHorizontal: 10,
//   },
//   headerLeftView: {
//     width: 40,
//   },
// })

export default ImageModalPage;
